<template>
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/enjoy_top.png"
    />
    <div class="box">
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/card1.png"
      />
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/enjoy_card_01.png"
      />
      <img
        class="card"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/card3.png"
      />
    </div>
    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />

      <van-field
        v-model="form.phone_code"
        type="number"
        center
        maxlength="6"
        autocomplete="one-time-code"
        placeholder="请输入验证码"
        class="input-code"
      >
        <template slot="right-icon">
          <div class="code" v-show="sendAuthCode" @click="getAuthCode">获取验证码</div>
          <div class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </div>
        </template>
      </van-field>
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/submit.png"
      />

      <div class="checked">
        <van-checkbox checked-color="#ffd614" v-model="checked" icon-size="14px">
          &nbsp;我已阅读并同意<span class="book" @click="dialogInfo"
            >《车主畅享惠服务协议》</span
          >
        </van-checkbox>
      </div>
    </div>
    <div class="info">
      <img
        class="title"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/title.png"
      />
      <div class="text">1.活动对象:2G/3G/4G/5G手机用户。</div>
      <div class="text">
        本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗。
      </div>
      <div class="text">2.活动内容:</div>
      <div class="text">
        订购车主畅享会员，29.9元/月，每30天可领取车主通用券10元*2张+视听会员权益*1张+打车券10元*1张，到期前24小时内将自动扣费续订。
      </div>
      <div class="text example">
        例如张三在2024年4月1日10:56分订购了车主畅享会员，那么在2024年4月1日10:56分~2024年4月30日10:55期间，张三可领取10元车主通用券2张+视听会员月卡1张+10元打车券1张。如张一在2024年4月30日10:55前未退订服务，那么在2023年4月30日10:55~2023年4月31日10:55期间将自动扣费下一个30天计费周期的费用。
      </div>
      <div class="text">
        3.生失效方式:
        订购立即生效，当前计费周期费用(30天)一次性扣除下一个计费周期自动续订，退订需在到期前24小时。
      </div>
      <div class="text">4.权益领取使用及有效期:</div>
      <div class="text">
        会员权益每个计费周期(30天)限领1次，逾期作废。领取路径:关注微信公众号【优享会员家】,点击【权益领取】，进入后选择需要的权益领取；
      </div>
      <div class="text">(1) 车主通用券使用说明：</div>

      <div class="text">
        ①本券在加油、充电、高速、停车、代驾、违章查缴、年检、洗车、租车、道路救援、驾照翻译可用，仅支持在有效期内使用。
      </div>

      <div class="text">②本券不可兑换现金，不可找零。</div>
      <div class="text">
        ③单笔支付只可核销1张优惠，不得转赠、共享，支付时不可以与其他优惠叠加使用。
      </div>
      <div class="text">④使用时，优惠金额-10元。</div>
      <div class="text">
        ⑤有效期自红包生效时间起7日内有效(请在优惠红包详情页面关注具体有效期信息)，建议领取后尽快使用，过期后无法使用。
      </div>

      <div class="text">⑥本券使用门槛以支付宝订单金额为准，不能拆分使用。</div>
      <div class="text">
        ⑦本券限实名认证用户才可使用，未实名认证用户需完成支付宝实名认证后可使用该优惠。
      </div>
      <div class="text">⑧本券仅限在中国大陆地区使用。</div>
      <div class="text">
        ⑨订单发生全额退款时，尚在有效期内的优惠可再次使用，退款后的红包有效期不变。如退款时红包超出有效期或有效期内订单部分退款，红包无法再次使用。
      </div>
      <div class="text">
        ⑩仅限中国大陆地区的支付宝实名用户参与，同一支付宝账号、手机号、身份证、手机终端，符合以上任一条件的，均视为同一用户。
      </div>
      <div class="text">
        ⑪本券仅支持支付宝可用的加油、充电、高速、停车场景，以及支付宝车生活小程序的代驾、违章查缴、年检、洗车、租车、道路救援、驾照翻译使用，商户自身/三方小程序不支持使用。
      </div>
      <div class="text">
        ⑫如果用户存在或涉嫌违法违规行为情况(包括不限于洗钱、虚假交易、作弊、套现、赌博、刷信用、支付宝基于自身风险政策或策略认定的风险交易等)的，支付宝将撤销用户活动参与资格和奖品使用资格，并有权回收用户已经获得的奖励权益(包括已消费金额)。
      </div>
      <div class="text">
        (2)视听会员权益使用说明：视听会员权益如腾讯爱奇艺登录对应平台即可使用；
      </div>

      <div class="text">（3）打车券使用说明：</div>

      <div class="text">—滴滴快车/专车</div>
      <div class="text">
        ①本品为代金券直充，仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
      </div>
      <div class="text">②虚拟商品，一经提交充值不支持退换。</div>
      <div class="text">
        ③一笔订单只能使用一张优惠券，并且只能抵扣充值到账户之后的生成的订单，优惠券不拆分、不找零，请合理选择是否使用优惠券，优惠不足部分需用电子支付方式补足。拼车、特惠快车无法使用。
      </div>

      <div class="text">—高德打车：</div>

      <div class="text">
        ①本品为代金券直充，仅支持账号绑定手机号码充值。请用户在充值前确保号码已开通滴滴账号。
      </div>
      <div class="text">②仅限在高德地图端内打车时使用，出租车、一口价订单不能使用。</div>
      <div class="text">
        ③打车券有效期为充值成功之日起7天内有效，请在券有效期内使用，过期失效作废。同一订单优惠券最多可使用一张，不可叠加使用;券仅限一次性使用，不可拆分，不可转让，不可提现。
      </div>
      <div class="text">
        ④充值成功后，登录[高德地图]
        app，点击[我的]-[钱包卡券]-[券]处查看使用规则及有效期情况。
      </div>
      <div class="text">
        对券的使用等有任何疑问可以拨打高德地图客服电话: 400-880-2252。
      </div>

      <div class="text">5.退订方式</div>
      <div class="text">
        退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后3个月内不能再次订购;
      </div>

      <div class="text">
        如需退订本产品，请登录【支付宝],点击右下角【我的]-【设置]-【支付设置]-【免密支付/自动扣款]在自动扣款界面，找到要取消自动续费的服务，点击【服务]。在服务详情界面，点击最下方【关闭服务]。出现选项，点击【关闭服务],即可成功退订本产品，退订后所有权益如在权益规定有效期内均可使用，逾期作废;
      </div>

      <div class="text">6.如对活动有疑问或更多需求，请咨询在线客服或拨打4001180238。</div>

      <div class="text">[订购须知]</div>
      <div class="text">
        1、本页面仅供客户购买权益，非用于鉴权登录等其他服务，老年及未成年人请谨慎消费，敬请知悉!
      </div>
      <div class="text">
        2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，道防网络电信诈骗。
      </div>
      <div class="text">3、虚拟权益一旦领取不支持退款，请谨慎选择。</div>
    </div>
  </div>
</template>

<script>
import { code, codeDlipayPay } from "@/api/carOwner_zhengxing/receive.js";

export default {
  data() {
    return {
      form: {
        phone_number: "",
        phone_code: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
    };
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await code({ phone_number });
      this.$toast.clear();
      if (res.code != 0) {
        this.$dialog.alert({ message: res.message });
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    async onSubmit() {
      let filtercode = /^\d{6}$/;
      let filterPhone = /^1[3456789]\d{9}$/;

      const { phone_number, phone_code } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$dialog.alert({ message: "手机号错误" });
      } else if (!filtercode.test(phone_code)) {
        this.$dialog.alert({ message: "请输入6位验证码" });
      } else if (!this.checked) {
        this.$toast({
          duration: 8000,
          message: "请阅读并同意车主畅享惠服务协议",
        });
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await codeDlipayPay({
          phone_number,
          phone_code,
          item_name: "车主畅享会员",
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
    dialogInfo() {
      this.$dialog.alert({
        messageAlign: "left",
        title: "车主畅享惠服务协议",
        message: `本协议是用户（以下亦称“您”）与铮行公司平台所有者浙江铮行科技有限责任公司（以下简称为“铮行公司”）之间就铮行公司平台服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击“同意以下协议，提交”按钮后，本协议即构成对双方有约束力的法律文件。

第1条本平台服务条款的确认和接纳

1.1本平台的各项电子服务的所有权和运作权归铮行公司所有。用户同意所有注册协议条款并完成注册程序，才能成为本平台的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。

1.2用户点击同意本协议的，即视为用户确认自己具有享受本平台服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。

1.3如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本平台。

1.4铮行公司保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。

第2条本平台服务

2.1铮行公司通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本平台规定的情况下，方有权使用本平台的相关服务。

第3条用户信息

3.1用户应自行诚信向本平台提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且铮行公司保留终止用户使用铮行公司各项服务的权利。

3.2用户在本平台进行浏览、下单购物等活动时，可能涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱、银行账户、房产信息等隐私信息的，本平台将予以严格保密，除非得到用户的授权或法律另有规定，本平台不会向外界披露用户隐私信息。

3.3用户同意，铮行公司拥有通过邮件、短信、电话等形式，向在本平台注册、购物用户、收货人发送订单信息、促销活动等告知信息的权利。

第4条用户依法言行义务 本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：

（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；

（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；

（3）不得利用本平台从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；

（4）不得干扰本平台的正常运转，不得侵入本平台及国家计算机信息系统；

（5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；

（6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；

（7）不得教唆他人从事本条所禁止的行为；

（8）不得利用在本平台注册的账户进行牟利性经营活动；

（9）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容； 用户应不时关注并遵守本平台不时公布或修改的各类合法规则规定。 本平台保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。 若用户未遵守以上规定的，本平台有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。 第5条商品信息 本平台上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本平台不作特别通知。由于平台上商品信息的数量极其庞大，虽然本平台会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本平台网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；铮行公司欢迎纠错，并会视情况给予纠错者一定的奖励。 为表述便利，商品和服务简称为“商品”或“货物”。

第6条订单

6.1在您下订单时，请您仔细确认所购商品的名称、价格、数量、规格、联系电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。

6.2除法律另有强制性规定外，双方约定如下：本平台上销售方展示的商品和价格等信息仅仅是要约邀请，您下单时须填写您的联系方式、姓名等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的合同要约；销售方收到该订单信息后，已将货物/服务实际直接向您发出时，方视为您与销售方之间就发出的货物/服务建立了合同关系。 第7条支付及自动续费规则

7.1用户在铮行公司平台中选购商品并提交订单后，用户需按照铮行公司平台提供的网上支付通道（下称“支付渠道”）在提交订单后合理的时间内支付显示的应付款项，如在显示的时间内用户未及时付款，则此笔订单将根据阶段的不同会被取消或无法进行下一阶段的服务。

7.2 您点击订阅、开通及使用腾讯视频系列会员自动续费服务(以下称“本自动续费服务”),则视为您同意本规则。

您理解并同意：

（1）如您通过支付渠道开通本自动续费服务，且在订阅期内未主动明确取消本自动续费服务，则支付渠道将在每个订阅期即将到期的前24小时从您开通本自动续费服务时绑定的支付渠道账户(以下统称“账户”)余额中自动代扣下一个订阅期的服务费，从而延长该订阅期对应的会员领取服务有效期(具体以支付渠道实际扣费规则为准)。您应关注上述账户及可扣款的余额状况，如因账户问题或余额不足导致续费失败等风险及/或损失将由您自行承担。

为便于您了解自动续费情况，支付渠道及/铮行公司将以【短信、站内信、微信推送或邮件】等方式进行扣款前通知，请您注意接收。需要提醒您特别注意，基于自动续费服务的特点，如果您在订阅期内未明确取消自动续费服务，支付渠道将根据您的委托向相关主体发出下一个订阅期的代扣指令，原则上对基于该指令扣除的费用将不予退还。

（2）订阅期：以订阅页面设置为准，会员可自行选择。

（3）您确认并同意，铮行公司拥有调整服务价格或停止提供自动续费服务的权利。如铮行公司拟对您开通自动续费服务时的服务价格做出调整，铮行公司将以适当方式(包括但不限于站内公告、短信等)事先通知您。如您不同意服务价格调整，您可以根据本规则取消本自动续费服务；如您未取消本自动续费服务，则支付渠道后续代扣的费用将以调整后的服务费用为准；如您在订阅期到期前24小时内取消，则支付渠道将可能会按照调整后的服务费用扣除下一个订阅期的服务费用后停止代扣服务。在本自动续费服务取消后，如您再次开通自动续费服务，支付渠道自动代扣的服务费用以届时页面显示的服务费用为准。

（4）取消方式

支付宝：【我的】-【设置】-【支付设置】-【免密支付/自动扣款】，选择美食随心享取消订阅。

第8条退订

8.1基于本平台提供服务的特殊性，不支持退换，一经订阅，即为有效，请您在购买前务必谨慎考虑。

第9条所有权及知识产权条款

9.1用户一旦接受本协议，即表明该用户主动将其在任何时间段在本平台发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部归铮行公司所有，用户同意铮行公司有权就任何主体侵权而单独提起诉讼。

9.2用户同意并已充分了解本协议的条款，承诺不将已发表于本平台的信息，以任何形式发布或授权其它主体以任何方式使用（包括但限于在各类平台、媒体上使用）。

9.3铮行公司是本平台的制作者,拥有此平台内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及本平台的内容进行修改，并在本平台张贴，无须另行通知用户。在法律允许的最大限度范围内，铮行公司对本协议及本平台内容拥有解释权。

9.4除法律另有强制性规定外，未经铮行公司明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本平台的信息内容，否则，铮行公司有权追究其法律责任。

9.5本平台所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是铮行公司的财产，受中国和国际版权法的保护。本平台上所有内容的汇编是铮行公司的排他财产，受中国和国际版权法的保护。本平台上所有软件都是铮行公司或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。

第10条责任限制及不承诺担保 除非另有明确的书面说明,本平台及其所包含的或以其它方式通过本平台提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。 除非另有明确的书面说明,铮行公司不对本平台的运营及其包含在本平台上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。 铮行公司不担保本平台所包含的或以其它方式通过本平台提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本平台发出的电子信件、信息没有病毒或其他有害成分。 如因不可抗力或其它本平台无法控制的原因使本平台销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，铮行公司会合理地尽力协助处理善后事宜。

第11条协议更新及用户关注义务 根据国家法律法规变化及平台运营需要，铮行公司有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本平台上即生效，并代替原来的协议。用户可随时登陆查阅最新协议；用户有义务不时关注并阅读最新版的协议及平台公告。如用户不同意更新后的协议，可以且应立即停止接受铮行公司平台依据本协议提供的服务；如用户继续使用本平台提供的服务的，即视为同意更新后的协议。本平台建议您在使用本平台之前阅读本协议及本平台的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。

第12条法律管辖和适用 本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。 如发生本平台服务条款与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。 如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，双方均同意提请杭州仲裁委员会按照其仲裁规则进行仲裁；该仲裁裁决为终局裁决，对双方均有约束力。

第13条其他

13.1铮行公司尊重用户和消费者的合法权利，本协议及本平台上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本平台欢迎用户和社会各界提出意见和建议，铮行公司将虚心接受并适时修改本协议及本平台上的各类规则。

13.2本协议内容中以黑体、加粗、下划线、或斜体等方式显著标识的条款，请用户着重阅读。

13.3您点击本协议上方的“同意以下协议，提交”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #ffd0b0;
  .top,
  .card {
    width: 100%;
  }

  .box {
    padding: 0 25px;
    margin-top: -330px;

    .card {
      padding-bottom: 33px;
    }
  }

  .form {
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/carOwner_zhengxing/input.png")
      center center/contain no-repeat;
    height: 604px;
    width: 750px;
    padding-top: 33px;
    box-sizing: border-box;

    ::v-deep .van-cell {
      width: 560px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 20px;
      border: solid 2px #c95d0e;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #781903;
          font-size: 28px;
          font-weight: normal;
          background-color: #ffdeb7;
          border-left: solid 2px #c95d0e;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #781903;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
          color: rgba(120, 25, 3, 0.5);
        }
      }
    }

    .submit {
      width: 572px;
      display: block;
      margin: 112px auto 0;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0 300px;

      ::v-deep .van-checkbox {
        width: auto;

        &__label {
          margin-left: 4px;
          color: #781903 !important;

          .book {
            color: #ffd614;
          }
        }
      }
    }
  }

  .info {
    padding: 66px 26px 160px;

    .title {
      width: 224px;
      display: block;
      margin: 0 auto;
      padding-bottom: 32px;
    }
    .text {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
      color: #c95d0e;
    }
    .example {
      font-size: 22px;
      letter-spacing: 0px;
      color: #d57c3b;
    }
  }
}
</style>
